


.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.tag-0{
  border-color:#962525;;


}
.tableinputField{
  height: 100%;
}
.tabilatorTitleHead{
  font-size: 14px;
  font-weight: bold;
  color: var(--gray-gray-700, #2D3748);
}
.tabilatorTitleEmail{
  color: var(--gray-gray-500, #718096);
  font-size: 14px;
  font-style: 400;

}

.tag-1{
  border-color: #28AE63;

}
.tag-2{
  border-color: #EC31F0;

}
.green{
  color:#5CDB94;
}
.spaceBetween{
  justify-content: space-between;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.tabulator-col-title-holder {
  color: white;
  font-size: medium;
  text-align: center;
  border-radius: 6px;
  background: #15A150;
}
.nobg{
  background-color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
