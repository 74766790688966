.one{
    margin: auto;
    text-align: center;
    border-radius: 10px;
    background: url('./../../../assets/images/theme1.jpg');
    width: 250px;
    aspect-ratio: 1.619;
    background-size:cover;
    .name{
        padding-top: 70px;
        font-size: 14px;
        font-weight: 700;
    }
   
    .designation{
        font-size: 12px;
    }
    .phone{
        margin-top:15px;
        font-size: 10px;
        img{
            width: 10px;
    margin: 0px 4px;
        }
    }
    .email{
        margin-top: 2px;
        font-size: 10px;
        img{
            width: 10px;
    margin: 0px 4px;
        }

    }
    

}
.card{
    position: absolute;
    transition: 0.5s;
    left: calc(50% - 150px);
}
@media (min-width: 700px) {
    .one{
        width: 594px;
       height: 320px;
       .name{
        padding-top: 140px;
        font-size: 30px;
       }
       .designation{
        font-size: 20px;
       }
       .phone {
        margin-top: 50px;
        font-size: 14px;
        img{
            width: 12px;
        }
       }
       .email {
        margin-top: 10px;
        font-size: 14px;
        img{
            width: 12px;
        }
       }
    }
    .card{
        left: calc(50% - 350px);

    }
    
}