.dashboardPage{
    width: 100%;
    min-height: 100vh;
    background-color:#F8F8F8;
    color: #F8F8F8;
    .header{
        padding: 10px;
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .headerLeftImage{
            padding: 10px;
        }
    }
    .dashboardStatics{
        padding: 10px 10px 30px 10px;
        border-bottom: 1px solid rgba(111, 108, 108, 0.56);
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr;
        grid-template-rows: 1fr;
        gap: 10px;
        height: 250px;
        .cardRecieved{
            text-align: center;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            h3{margin: 10px;}
            h1{
                margin: 10px;
            }
           

        }
        .cardShared{
            display: grid;
            height: 100%;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 10px;
            .customerShared{
                color:var(--gray-gray-700, #2D3748);
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
            .cusSupTitle{
                font-weight: bold;
                font-size: 18px;
            }

        }
        .industryAnalytics{
            padding:16px 10px;
            color: var(--gray-gray-700, #2D3748);
            .analyticsHead{
                font-size: 22px;
                font-weight: bold;
            }
            .analyticssubHead{
                font-size: 14px;
                font-weight: 600;
                color: var(--gray-gray-400, #A0AEC0) !important;
            }
            .anyliticsContent{
                height: -webkit-fill-available;
                overflow-y: auto;
                margin-top: 10px;
                height: 150px;
                overflow-y: auto;
                
                .analyticsContentHead{
                    font-size: 22px;
                font-weight: bold;
                }
                .analyticsGroup{
                    margin: 10px 0px;
                    align-items: center;
                }
                .analyticsValue{
                    font-size: 30px;
                    font-weight: bolder;
                    border-radius: 12px;
                    color: var(--White, #FFF);
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                   background: #40E18C;
                    width: 45px;
                    height: 45px;
                    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
                }
            }
        }
    }
}
.black{
    color: black;
}
.tableData{
    font-weight: bold;
}
.dataType{
    border-radius: 8px;
    background: #2EE584;
    color: white;
    height: 35px !important;
    text-align: center;
}
.box{
           
    background: var(--White, #FFF);
    border-radius: 15px;
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
}
.box1{
    background: linear-gradient(82deg, #313860 2.25%, #151928 79.87%);
    border-radius: 15px;
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
}
.dashboardSearch{
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 10px 50px;
    overflow: hidden;
}
.dashboardSearchField:focus-visible{
outline: none;
}
.dashboardSearchField {
    :focus-visible{
      outline: none;
    }
    :active{
      border: none;
    }
  
   min-width: 50vw;
    border: none;
    color:#9D9D9D;
    font-size: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.50);;
    font-weight: 700px !important;
    
  }
.tabulator-react{
    color:  var(--gray-gray-700, #2D3748);;
}  
.tableCheckbox{
    position: absolute;
    z-index: 90;
    left: 6px 
    
}