
.cardGroup{
    margin-top: 50px;
    z-index: 10;
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
}
.prevnextButton{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.groupicon{
    justify-content: center;
    color: #eff3e7;
    margin: 80px 0px;
    display: flex;
    align-content: center;
    .divide{
        font-size: 28px;
        vertical-align: top;
    padding: 0px 20px;
    }
    img{
        cursor:pointer;
        width: 25px;
        height: 25px;
        border: 2px solid white;
        border-radius: 50%;
        padding: 4px;
    }
    
}
.arrowIcon{
    width: 50px;
}
.circleGroup{
    display: flex;
    align-self: center;
}
.activeCircle{
    background-color:#503cad;
}
.deletePopupHeading{
    padding: 20px;
    align-items: center;
}
.commonPopup{
    padding: 20px;
}
.actionButton{
    display: flex;
    justify-content: center;
    gap: 20px;
}
.deleteHeading{
    margin-left: 10px;
    color: rgba(23, 15, 73, 0.80);

font-feature-settings: 'clig' off, 'liga' off;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
 /* 95.833% */
}
.addCardHeading{
   
    color: rgba(23, 15, 73, 0.80);

font-feature-settings: 'clig' off, 'liga' off;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;

}
.deleteCard{
    color: #28B064;
font-family: Nunito;
font-size: 21.247px;
font-style: normal;
font-weight: 800;
line-height: normal;

}
.circle{
    margin: 0px 2px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border:1px solid white;

}
.addButton{
    position: absolute;
    right: 5px;
    z-index: 12;
    width: 100px;
    top: 30px;

}
.iconNames{
    display: none;
}
.addCardNamePopupHeading{
    text-align: center;
    

}
.cardName{
    left: calc(50% - 150px);
    color: white;
    position: relative;
    margin-bottom: 2px;

}
.addCardInput{
    margin: 10px 0px;
    input{
        width: 100%;
        border-radius: 4px;
        border: none;
        padding: 4px;
    }
    input:focus-visible{
        outline: none;
    }
    
    
}
@media (min-width: 700px) {
    .cardGroup{
        margin-top: 100px;
        height: 400px;
    }
    .cardName{
       
        left: calc(50% - 350px);
    }
    .circle{
        width: 24px;
    height: 18px;
    border-radius: 10px;
    }
    .addButton{
        top: 80px;
        right: 80px;
        width: 150px;
    }
    .arrowIcon{
        width: 80px;
    }
    .groupicon .divide {
        filter: blur(1px);

    }
    .iconNames{
        display: contents;
        vertical-align: middle;

    }
    .groupicon{
        background-color:  #1B1A1A;
        border: 2px solid #EEF2E6;
        border-radius: 9px;
        width: max-content;
        margin: 50px auto;
        padding: 10px;
        .groupIconContainer{
            display: flex;
    justify-content: flex-start;
    align-items: center;
        }
        img{
            border: none;
            width: 40px;
            height: 40px;
        }
    }
}
