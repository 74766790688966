
@import url('https://fonts.googleapis.com/css?family=Outfit');
:root{
  --desktop-breakpoint:700px;
}
html{
  text-align: left;
  background: #131126;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.textGreen{
  color: #46D5A0;
}
.pageContent{
  padding: 0px 16px ;
}
.secondary{
  background:transparent;
  border: 1px solid #41EE8D;
  color:#41EE8D;

}
.sharePrimarybtn{
  margin: 4px;
  background: white;
  border-radius: 8px;
}
.primary{
  color: white;
  background-color: #28B064;
  border: none;

}
.btn{
  padding: 8px;
  border-radius: 12px;
  width: 150px;
  font-size: 15px;
  font-weight: 700;

}
.bg-transparent{
  background: transparent;
}
.popup {
  z-index: 20;
  display: flex;
    justify-content: center;
    align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  .crossIcon{
    position: absolute;
    top: 12px;
    right: 12px;
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
  position: absolute;
  max-width: calc(100vw - 20px);;
  height: auto;
  border-radius: 16px 16px 0px 0px;
  padding: 5px;
  
  margin: auto;
  background:#EDF5E0;;
}
.input:focus-visible {
  border-color: #46D5A0;
 
}
.disabled{
  opacity: 0.5;
  cursor: not-allowed;
   pointer-events: none;
  
}
.flex{
  display: flex;
}
.col{
  flex-direction: column;
}
.row{
  flex-direction: row;
}

.greenUnderline{
  border-bottom: 1px solid #46D5A0;
}
body {
  margin: 0;
  font-family:Outfit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
