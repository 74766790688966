.headerGroup {
  color: #eff3e7;
  text-align: left;
  padding: 80px 80px 0px 0px;
  svg {
    display: none;
  }
  .subHeading {
    font-size: 12px;
  }

  .cardHeader {
    font-size: 22px;
    line-height: normal;
    font-style: normal;
    font-weight: 600;
    .animatedHeader {
      text-shadow: 0px 4px 86px rgba(92, 219, 148, 0.1);
      background: var(
        --Special,
        linear-gradient(
          90deg,
          #1580e7 -23.36%,
          #348ce0 -2.6%,
          #569bdd 34.25%,
          #41ee8d 49.32%,
          #40e18c 54.99%,
          #29ddba 88.08%,
          #389583 88.08%
        )
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.profileTag{
  display: flex;
  flex-wrap: wrap;
  .tagitem{
    padding: 4px;
    color: #1E1E1E;
    margin-left: 4px;
    border-left: 8px solid;
    font-size: 12px;
    border-radius: 0px 3px 3px 0px;
    background: #F5F5F5;
   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    margin: 4px;
  }
  .tag-0{
    border-color:#962525;;


  }
  .tag-1{
    border-color: #28AE63;

  }
  .tag-2{
    border-color: #EC31F0;

  }
  .tag-3{
    border-color: #22B5D6;;
  }
  .tag-4{
    border-color: #6D58D0;;
  }
}

.tipsContainer{
  width: 100%;    
  display: flex;
  justify-content: flex-end;
  .tips{
    background-image: url('./../../assets/icons/tipsBackground.png');
    background-size: cover;
    width: 250px;
    height: 120px;
    border: 0.5px solid var(--2, #1580E7);
    border-radius: 18px;
    margin-top: 40px;
    margin-right: 95px;
    padding: 4px;
    color:#ccc;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align-last: center;
    .tipHeading{
      font-size: large;
      text-align: center;
      font-weight: 600;
      margin-top: 6px;

    }
    
    .content{
      font-size: 12px;
      width: 200px;
    padding: 20px 15px 0px 15px;
    text-align: center;
    margin: auto;

    }
  }
  img{
    position: absolute;
    right: 0px;
    height: 350px;
  }

}
.detailsContainer{
  justify-content: space-between;
  align-items: center;
  .profileImage{
    margin-left: 40px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    overflow: hidden;
   

  }
  .profileHeaderContent{
    max-width: 60%;
    text-shadow: 0px 4px 132px rgba(0, 0, 0, 0.80);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #EFF3E7; 
    #stallNo{
      margin: 4px 0px;
     
       

    }
    #profileName{
      margin: 4px 0px;
    }
    #profileLocation{
      color:  #40E18C;
      margin: 4px 0px;
    }
    #profileUrl{
      margin: 4px 0px;
      color: #218EF8;
      img{
        margin-right: 4px;
      }
    }

  }
}
.shareCardList{
  margin-top: 20px;
  margin-bottom: 20px;
}
.optionBox {
  background-color: white;
  margin: 0px 10px;
}
.optionHeader {
  font-size: 14px;
  padding: 10px;
  font-style: normal;
  font-weight: 400;
  color: black;
  background-color: #ececec;
  height: 36px;
  margin: auto;
  display: flex;

  align-items: center;
  img {
    margin-right: 10px;
  }
}
.shareCompanyItemContainer{
  min-height: 200px;
    max-height: 300px ;
  overflow-y: auto;
  overflow-x: hidden;
  .selected{
    background-color: #28b064;
  }
  .companyList{

    border: 0.4px solid  #5CDB94;
    margin: 4px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    h1 {
       
    
        padding: 4px 0px;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        /* 150% */
        margin: 0px;
      }
    h3{
      margin: 0px;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      

    }


  }
  
  img{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #5CDB94;
  }
}
.searchContainer {
  margin: 0px 10px;
  padding: 10px 20px;
  background-color: white;
  height: 32px;
  display: flex;
  border-radius: 10px;
  justify-content: flex-start;
  align-self: center;
  img{
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    height: 15px;
    width: 15px;

  }
  .shareSearch {
    :focus-visible{
      outline: none;
    }
    :active{
      border: none;
    }
  
   min-width: 250px;
    border: none;
    color:#9D9D9D;
    font-size: 12px;
    font-weight: 700px !important;
    
  }
  .shareSearch :focus-visible{
    outline: none;
  }
}
.warning {
  font-size: 14px;
  color: #c73c3c;
  padding: 12px 50px;
  margin: 6px;
}

.optionsContainer {
  border-bottom: 0.923px solid #eaf6ff;

  padding: 8px 10px;
  justify-content: flex-start;
  align-items: end;
  .option {
    color: black;
    font-weight: 300;
  }
  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}
.inputBox {
  section {
    margin-top: 10px;
  }
  .inputBoxHeader {
    border-bottom: 1px solid white;
    text-align: left;
    .header {
      color: #eff3e7;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 22.5px;
    }
    .subheader {
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 10px;
      /* 83.333% */
      margin-bottom: 4px;
      letter-spacing: -0.42px;
    }
  }
}
.shareContainer {
  text-align: center;
  color: #28b064;
  min-width: 350px;
}
.groupIcon {
  display: flex;
  justify-content: center;
}
.iconContainer {
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 130px;
  height: 120px;
  border-radius: 9.219px;
  background: var(--White, #fff);
  img {
    width: 34px;
    height: 32px;
  }
  .header {
    color: #41ee8d;

    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .subHeader {
    font-size: 10px;
    font-weight: 400;
    color: #3b3b3b;
  }
}
.submit {
  display: flex;
  width: 117px;
  height: 27px;
  margin: 20px auto !important;
  padding: 20px 40px 21px 40px;
  justify-content: center;
  align-items: center;
  color: var(--White, #fff);
  font-size: 14px;
  font-weight: 700;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    #156fc5 3.48%,
    #5cdb94 100.29%,
    #ec31f0 110.92%,
    #28b064 110.92%
  );

  box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.18);
}
.inputGroupHeading {
  padding-top: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #f2f3e7;
  line-height: 21px;
}
.inputFieldContainer {
  justify-content: space-between;
  flex-flow: wrap;

  .inputContainer {
    width: 48%;
  }
}
.companyDetailsInput {
  width: 48%;
}
@media (min-width: 700px) {
  .headerGroup {
    display: flex !important;
    justify-content: space-between;
    padding: 0px;
    svg {
      display: block;
      position: static;
      margin: auto;
      stroke-width: 2px;
      stroke: #40e18c;

      filter: blur(1px);
    }
    .cardHeader {
      font-size: 44px;
      width: 50%;
    }
    .subHeading {
      font-size: 18px;
      width: 40%;
      margin: auto;
    }
  }
}
.addNewRequirementInput{
  input{
    width: 90%;
    margin: 12px;
    padding: 4px;
    border: 2px solid #28b064;
    border-radius: 8px;
  }
  input:focus-visible{
    outline: none;
  }
}
