.aboutUs{
    margin: 20px 0px;
    background-color: #F2F3E7;
   
    padding: 10px;
    .aboutUsHeading{
        color: #1E1E1E;
        
      
      text-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
      font-family: Outfit;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      padding: 10px 0px;
      line-height: 22px; /* 122.222% */
      }
      .aboutUsContent{
        display: flex;
        .c{
            max-width: 65%;
        }
        .brochure{
            margin-top: 20px;
            text-align: center;
            border-radius: 24px;
             border: 0.5px solid var(--2, #1580E7);

           

             box-shadow: 0px 4px 43px 0px rgba(75, 214, 139, 0.20);
            background-image: url(./../../assets/icons/tipsBackground.png);
            height: max-content;
            .brochureContent{
                color: white;
                font-size: 16px;
                font-weight: 700;
                padding: 4px;
                margin: 4px;
            }
            
        }
      }
}
.submitBrochure{
    display: flex;
    width: -webkit-fill-available;
    height: 38px;
    margin: 12px 10px!important;
    padding: 5px 0px 5px 0px;
   justify-content: center;
align-items: center;
color: var(--White, #fff);
font-size: 14px;
font-weight: 700;
gap: 8px;
flex-shrink: 0;
border-radius: 20px;
background: linear-gradient(90deg, #156fc5 3.48%, #5cdb94 100.29%, #ec31f0 110.92%, #28b064 110.92%);
box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.18)
}
.facilities{
    .facilitiesHeading{
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #F5F8F0;;
    }
}
.profileCircleGroup{
    display: flex;
    align-self: center;
    justify-content: center;
}
.profileActiveCircle{
    background-color:#503cad;
}
.profileCircle{
    margin: 0px 4px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border:1px solid black;

}
.facilitiesContent{
    width:-webkit-fill-available;
    height: 200px;
    overflow: hidden;
    max-width: 900px;
    background-color: #F2F3E7;
    position: absolute;
    margin: 10px 0px;
    .profileLeftArrow{
        position: absolute;
        top: 80px;
        left: 8px;
        z-index: 20;

    }
    .profileRightArrow{
        position: absolute;
        top: 80px;
        right: 8px;
        z-index: 20;

    }

}
.facilitiesCardParent{
   height: 180px;
    z-index: 10;
    width: 80%;
        margin: auto;
    position: relative;
   
    .facilitiesCard{
        max-width: 350px;
        position: absolute;
        transition: 0.5s;
        text-align: center;
       
        .facilitiesCardHeading{
            font-size: 18px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
     
    }

}
.photoSection{
    margin-top: 260px;
    .photoHeading{
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        color: #F5F8F0;;
        }
}
.activeImageContent{
    text-align: center;
    padding: 10px 20px;
    .photoCardHeading{
        color: #5CDB94;

font-family: Outfit;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;

    }
    .photoCardSubHeading{
        color: #F5F8F1;

text-align: center;
font-family: Outfit;
font-size: 10px;
font-style: normal;
font-weight: 300;
line-height: 9px; /* 90% */
letter-spacing: -0.19px;

    }
}
.scrollpic{
    border: 2px solid white;
    display: flex;
    justify-content: space-between;
    padding: 2px;
    align-items: center;
}
.photoactive{
    border: 2px solid #5CDB94;
    
    
}
.buttonBottom{
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: space-between;
   .buttonsProfile{
    color: #EEF3E7;

font-family: Outfit;

font-style: normal;
font-weight: 600;

    width: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    margin: auto;
    img{
        margin-right: 10px;
    }
   }
}