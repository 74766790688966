.exhibitorlistContainer {
  margin-top: 60px;
  .profileNo {
    padding: 4px;
    margin-bottom: 8px;
    text-align: center;
    color: #f3fce2;

    text-shadow: 0px 4px 132px rgba(0, 0, 0, 0.8);
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    span{
        color: #40E18C;

    }
   
  }
  .exhibitorSearch{
    background: transparent;
    border-radius: 8px;
    border: 1px solid var(--Special, #1580E7);    
}
}
.exhibitorContainer{

  padding: 6px;
  display: flex;
  justify-content: space-between;
  .exhibitorContent{
   
    border-radius: 18px;
    width: 90%;
    overflow: hidden;
    width: 90%;
    .h{
      display: flex;
      height: 30px;
      justify-content: space-between;
      background: #5CDB94;
     
     box-shadow: 0px 1px 8px 0px rgba(110, 110, 110, 0.10);
     .id{
        font-size: 15px;
        font-weight: bold;
        margin: auto;
        img{
          vertical-align: sub;
          width: 19px;
          height: 19px;
          margin: 0px 10px 0px 15px;
    
         }
     }
     .exhibitorLocation{
      color: #fff;
      margin: auto;
      font-size: 15px;
      img{
        // vertical-align: sub;
       
        margin: 0px 4px 0px 15px;
  
       }

     }
    

    }
    .b{
      background-color: white;
      .name{
        padding: 10px;
        display: flex;
        justify-content: space-around;
        .exhibitorIcon{
          margin-left: 10px;
          img{
            width: 40px;
            height: 40px;

          }
        }
        .exhibitorheadsub{
          .head{
            font-size: 18px;
            font-weight: bold;
          }
          .sub{
            font-size: 12px;
            color: rgba(28, 28, 28, 0.80);
          }
          .tag{
            display: flex;
            .tagitem{
              padding: 4px;
              margin-left: 4px;
              border-left: 8px solid;
              font-size: 12px;
              border-radius: 0px 3px 3px 0px;
              background: rgba(243, 252, 226, 0.59);
             box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
              margin: 4px;
            }
            .tag-0{
              border-color:#962525;;


            }
            .tag-1{
              border-color: #28AE63;

            }
            .tag-2{
              border-color: #EC31F0;

            }
          }
        }
      }
    }
  }
  .exhibitorIcon{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 4px;
    width: 10%;
    
    img{
      width: 28px;
     
    }

  }
}
