.inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding:6px 0px;
  label {
    color: #f2f3e7;
    align-self: baseline;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-bottom: 2px;
  }
  input  {
    height: 20px;
    border-radius: 0px 27px 27px 0px;
    border: 1px solid var(--neutral-300, #eff0f6);
    background: var(--White, #fff);
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
  }
  select{
    height: 24px;
    border-radius: 0px 27px 27px 0px;
    border: 1px solid var(--neutral-300, #eff0f6);
    background: var(--White, #fff);
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
    color: black;
  }
  select:focus-visible{
    outline: none;
    

  }
}
.exhibitorHeader{
  color: #EFF3E7;

text-shadow: 0px 4px 132px rgba(0, 0, 0, 0.80);

font-size: 28px;
font-style: normal;
font-weight: bold;
line-height: normal;
text-transform: capitalize;
.highlight{
  padding-left: 6px;
  color: #3ADE99;
}


}
.exhibitorSubHeader{
  color:#EFF3E7;
  margin-top: 8px;
  font-size: 16px;
  width: 300px;
}
.primaryGreen{
  border-radius: 6px;
background: #28B064;
color: white;
box-shadow: 0px -1px 33.5px 0px rgba(0, 0, 0, 0.20);
border: none;
}
.sharePopup{
  width: 300px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;

}
